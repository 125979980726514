.a-tab {
  font-family: Biryani;
  margin: 0;
  display: block;

  color: black;
  background-color: #66a686;
}

.text-area {
  margin: 5vh 10vw;
}

.text-area li {
  background-color: #66a686;
}

h1 {
  font-size: 2.5vw;
}

h2 {
  font-size: 1.7vw;
}

.project-name-and-buttons {
  display: flex;
  align-items: center;
}

h2.a-tab.text-area.project-name-and-buttons {
  margin-right: 0.2vw;
}

h3 {
  font-size: 1.3vw;
}

p {
  font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.5vw;
}

ul {
  font-size: 1.5vw;
}

li {
  font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: black;
}

@media only screen and (max-width: 768px) {
  .a-tab {
    font-family: Biryani;
    margin: 0;
    padding: 0.5vh 0.5vw;
  }

  .text-area li {
    background-color: #66a686;
    font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  h1 {
    font-size: 4vw;
  }

  h2 {
    font-size: 2.8vw;
  }

  h3 {
    font-size: 2.3vw;
  }

  p {
    font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 3vw;
  }

  ul {
    font-size: 3vw;
  }

  li {
    font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: black;
  }
}
