.description-background {
  background-color: rgba(54, 99, 76, 0.7);
}

#greeting-text {
  font-family: Biryani;
  font-size: 7vh;
  margin-top: 10vh;
  padding: 17vh 5vh;
  padding-bottom: 0vh;
  margin-bottom: 0;
}

#my-description {
  font-family: Biryani;
  font-size: 4vh;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 2vh;
}

.very-cool-red-hover-effect {
  display: inline;
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
}

.very-cool-red-hover-effect:hover {
  color: #cd5c5c;
}
