#icon-bar {
  overflow: hidden;
  list-style-type: none;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  background: transparent;
}

.icon-button {
  border: 0.2vw solid white;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  width: 100%;
  padding-bottom: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 0vw;
  width: 6vw;
  height: 6vw;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  margin: 1vh 1vw;
}

.icon-button img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 40%;
  height: 40%;
}

.color-svg-white {
  -webkit-filter: invert(100%) sepia(0%) saturate(3972%) hue-rotate(129deg)
    brightness(119%) contrast(111%);
  filter: invert(100%) sepia(0%) saturate(3972%) hue-rotate(129deg)
    brightness(119%) contrast(111%);
}

.icon-button:hover {
  background-color: rgb(255, 255, 255, 0.5);
  border: 0.7vw solid rgb(255, 255, 255, 0.5);
}

/* @-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
} */

.tooltip-theme {
  font-size: 0.8vw !important;
  font-family: Biryani !important;
  font-weight: bolder !important;
  background-color: #fff !important;
}

.tooltip-theme.place-bottom::after {
  border-bottom: 8px solid #fff !important;
}

@media only screen and (max-width: 768px) {
  .icon-button {
    width: 15vw;
    height: 15vw;
    margin: 1vh 2vw;
  }
}
