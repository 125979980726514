@import url("https://fonts.googleapis.com/css?family=Biryani|Oxygen|Roboto&display=swap");

body,
ul {
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #ffffff;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #66a686;
}

::-webkit-scrollbar {
  width: 1vw;
}

::-webkit-scrollbar-thumb {
  background: #387759;
}

::-webkit-scrollbar-thumb:hover {
  background: #2b5c45;
}

::-webkit-scrollbar-track-piece {
  background: #ffffff;
}

.scroll-up-button {
  background-color: transparent !important;
  fill: #ffffff !important;
  outline: none !important;
}

.scroll-up-button:hover {
  -webkit-transition-duration: 0.2s !important;
  -o-transition-duration: 0.2s !important;
  transition-duration: 0.2s !important;
  opacity: 0.6 !important;
}
