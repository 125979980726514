/* #stack-arrows {
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-top: 4vh;
  padding-bottom: 4vh;
} */

#down-arrow div {
  color: #fff;
  text-decoration: none;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-top: 4vh;
  padding-bottom: 4vh;
  /* border-bottom: 0.3vh solid #ffffff; */
}

#down-arrow div:hover {
  cursor: pointer;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

#down-arrow div span {
  border-left: 0.2vw solid #fff;
  border-bottom: 0.2vw solid #fff;
  width: 2vw;
  height: 2vw;

  -webkit-transform: rotate(-45deg);

  -ms-transform: rotate(-45deg);

  transform: rotate(-45deg);

  -webkit-animation: scrolldownbutton 2s infinite;

  animation: scrolldownbutton 2s infinite;
}

#down-arrow div span:hover {
  opacity: 0.6;
}

#down-arrow div span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes scrolldownbutton {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scrolldownbutton {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
