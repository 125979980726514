#navbar {
  font-family: Biryani;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #387759;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}

a.link-tab {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  float: left;
  color: #ffffff;
  padding: 2vw 3vw;
  font-size: 1.2vw;
  text-decoration: none;
  background-image: -o-linear-gradient(#024b30, #024b30);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#024b30),
    to(#024b30)
  );
  background-image: linear-gradient(#024b30, #024b30);
  background-size: 0 1vw, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.link-tab img {
  padding-right: 0.3vw;
}
a.link-tab:after {
  -webkit-transition: width 0.5s ease, background-color 0.5s ease;
  -o-transition: width 0.5s ease, background-color 0.5s ease;
  transition: width 0.5s ease, background-color 0.5s ease;
}

a.link-tab.active {
  background-size: 100% 0.7vh, auto;
  background-color: #66a686;
}

a.link-tab:hover {
  background-size: 100% 0.7vh, auto;
}

@media only screen and (max-width: 768px) {
  #navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
  }

  .link-tab {
    text-align: center !important;
    display: -ms-grid !important;
    display: grid !important;
    place-items: center !important;

    font-size: 3vw !important;
    padding: 3vw 0 !important;

    border-bottom: none !important;
    background-size: 100% 100% !important;
    background-image: none !important;
  }
  .link-tab img {
    padding-right: 1vw;
  }
  a.link-tab {
    width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }

  a.link-tab.active {
    background-color: #024b30;
  }
}
