@import url(https://fonts.googleapis.com/css?family=Biryani|Oxygen|Roboto&display=swap);
.description-background {
  background-color: rgba(54, 99, 76, 0.7);
}

#greeting-text {
  font-family: Biryani;
  font-size: 7vh;
  margin-top: 10vh;
  padding: 17vh 5vh;
  padding-bottom: 0vh;
  margin-bottom: 0;
}

#my-description {
  font-family: Biryani;
  font-size: 4vh;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 2vh;
}

.very-cool-red-hover-effect {
  display: inline;
  transition-duration: 300ms;
}

.very-cool-red-hover-effect:hover {
  color: #cd5c5c;
}

#icon-bar {
  overflow: hidden;
  list-style-type: none;
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  background: transparent;
}

.icon-button {
  border: 0.2vw solid white;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  width: 100%;
  padding-bottom: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0vw;
  width: 6vw;
  height: 6vw;
  transition-duration: 0.2s;
  margin: 1vh 1vw;
}

.icon-button img {
  object-fit: cover;
  width: 40%;
  height: 40%;
}

.color-svg-white {
  -webkit-filter: invert(100%) sepia(0%) saturate(3972%) hue-rotate(129deg)
    brightness(119%) contrast(111%);
  filter: invert(100%) sepia(0%) saturate(3972%) hue-rotate(129deg)
    brightness(119%) contrast(111%);
}

.icon-button:hover {
  background-color: rgb(255, 255, 255, 0.5);
  border: 0.7vw solid rgb(255, 255, 255, 0.5);
}

/* @-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
} */

.tooltip-theme {
  font-size: 0.8vw !important;
  font-family: Biryani !important;
  font-weight: bolder !important;
  background-color: #fff !important;
}

.tooltip-theme.place-bottom::after {
  border-bottom: 8px solid #fff !important;
}

@media only screen and (max-width: 768px) {
  .icon-button {
    width: 15vw;
    height: 15vw;
    margin: 1vh 2vw;
  }
}

/* #stack-arrows {
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-top: 4vh;
  padding-bottom: 4vh;
} */

#down-arrow div {
  color: #fff;
  text-decoration: none;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-top: 4vh;
  padding-bottom: 4vh;
  /* border-bottom: 0.3vh solid #ffffff; */
}

#down-arrow div:hover {
  cursor: pointer;
  transition-duration: 0.5s;
}

#down-arrow div span {
  border-left: 0.2vw solid #fff;
  border-bottom: 0.2vw solid #fff;
  width: 2vw;
  height: 2vw;

  transform: rotate(-45deg);

  -webkit-animation: scrolldownbutton 2s infinite;

  animation: scrolldownbutton 2s infinite;
}

#down-arrow div span:hover {
  opacity: 0.6;
}

#down-arrow div span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes scrolldownbutton {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scrolldownbutton {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#landing {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  margin-top: 0;
  background: url(/static/media/landscapeportrait2center.ba79df47.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  max-height: 100vh;
}

#color-background {
  background-color: rgba(54, 99, 76, 0.7);
  max-width: 100%;
  width: 100vw;
  height: auto;
}

img.small-button {
  height: 2.5vw;
  margin-left: 0.5vw;
  -webkit-filter: invert(100%) sepia(0%) saturate(3972%) hue-rotate(129deg)
    brightness(119%) contrast(111%);
  filter: invert(100%) sepia(0%) saturate(3972%) hue-rotate(129deg)
    brightness(119%) contrast(111%);
}

#card {
  /* background: -o-linear-gradient(
    124deg,
    rgba(24, 78, 104, 1) 0%,
    rgba(77, 143, 109, 1) 100%
  );

  background: linear-gradient(
    326deg,
    rgba(24, 78, 104, 1) 0%,
    rgba(77, 143, 109, 1) 100%
  ); */

  background-color: #387759;
  color: #ffffff;
  margin: 3vh 5vw;
  border-radius: 1vw;
  box-shadow: 0 0.3vh 3px 3px #023622;

  transition-duration: 0.1s;
  overflow: hidden;
  display: flex;
}

#card-title-container {
  width: 100%;
  /* background: -o-linear-gradient(
    305deg,
    rgba(24, 78, 104, 1) 0%,
    rgba(77, 143, 109, 1) 100%
  );
  background: linear-gradient(
    145deg,
    rgba(24, 78, 104, 1) 0%,
    rgba(77, 143, 109, 1) 100%
  ); */
  background-color: #387759;

  display: flex;
  align-content: center;
}

#card-title {
  margin: 2vw 1vw 1vw 5vw;
  color: #ffffff;
}

#card:hover {
  box-shadow: 0 0.5vh 1.5vh 4px #023622;

  transform: translateY(-2%);
}

.header-container {
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 5vw;
}

h2.card-header {
  font-size: 2vw;
}

#description-container {
  margin: 3vh 5vw;
}

#card-bullet-list-container {
  margin: 5vh 5vw;
}

ul#card-bullet-list {
  background-color: transparent;
}

li.card-bullet-point {
  color: #ffffff;
}

#card-technologies-container {
  padding-left: 5vw;
}

/* #card-technologies {
} */

#card-image-container {
  overflow: hidden;
  width: 29vw;
  height: 30vw;
  display: flex;
  justify-content: center;
  border-radius: 0.5vw;
}

img#card-image {
  height: 100%;
}

.a-tab {
  font-family: Biryani;
  margin: 0;
  display: block;

  color: black;
  background-color: #66a686;
}

.text-area {
  margin: 5vh 10vw;
}

.text-area li {
  background-color: #66a686;
}

h1 {
  font-size: 2.5vw;
}

h2 {
  font-size: 1.7vw;
}

.project-name-and-buttons {
  display: flex;
  align-items: center;
}

h2.a-tab.text-area.project-name-and-buttons {
  margin-right: 0.2vw;
}

h3 {
  font-size: 1.3vw;
}

p {
  font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.5vw;
}

ul {
  font-size: 1.5vw;
}

li {
  font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: black;
}

@media only screen and (max-width: 768px) {
  .a-tab {
    font-family: Biryani;
    margin: 0;
    padding: 0.5vh 0.5vw;
  }

  .text-area li {
    background-color: #66a686;
    font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  h1 {
    font-size: 4vw;
  }

  h2 {
    font-size: 2.8vw;
  }

  h3 {
    font-size: 2.3vw;
  }

  p {
    font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 3vw;
  }

  ul {
    font-size: 3vw;
  }

  li {
    font-family: Oxygen, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: black;
  }
}

#navbar {
  font-family: Biryani;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #387759;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

a.link-tab {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  float: left;
  color: #ffffff;
  padding: 2vw 3vw;
  font-size: 1.2vw;
  text-decoration: none;
  background-image: linear-gradient(#024b30, #024b30);
  background-size: 0 1vw, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}
.link-tab img {
  padding-right: 0.3vw;
}
a.link-tab:after {
  transition: width 0.5s ease, background-color 0.5s ease;
}

a.link-tab.active {
  background-size: 100% 0.7vh, auto;
  background-color: #66a686;
}

a.link-tab:hover {
  background-size: 100% 0.7vh, auto;
}

@media only screen and (max-width: 768px) {
  #navbar {
    display: flex;
    position: relative;
  }

  .link-tab {
    text-align: center !important;
    display: -ms-grid !important;
    display: grid !important;
    place-items: center !important;

    font-size: 3vw !important;
    padding: 3vw 0 !important;

    border-bottom: none !important;
    background-size: 100% 100% !important;
    background-image: none !important;
  }
  .link-tab img {
    padding-right: 1vw;
  }
  a.link-tab {
    width: 100% !important;
    display: flex;
    justify-content: center;
    transition-duration: 0.2s;
  }

  a.link-tab.active {
    background-color: #024b30;
  }
}

#credits {
  width: 100%;
  background-color: #387759;
  font-size: 1vw;
  text-align: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  #credits {
    font-size: 2vw;
  }
}

#credits-bar {
  border-top: 0.3vh solid #ffffff;
  padding-top: 2.5vh;
  max-width: 100%;
  background-color: #387759;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

body,
ul {
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #ffffff;
  align-content: center;
  background-color: #66a686;
}

::-webkit-scrollbar {
  width: 1vw;
}

::-webkit-scrollbar-thumb {
  background: #387759;
}

::-webkit-scrollbar-thumb:hover {
  background: #2b5c45;
}

::-webkit-scrollbar-track-piece {
  background: #ffffff;
}

.scroll-up-button {
  background-color: transparent !important;
  fill: #ffffff !important;
  outline: none !important;
}

.scroll-up-button:hover {
  transition-duration: 0.2s !important;
  opacity: 0.6 !important;
}

