#landing {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  margin-top: 0;
  background: url("../../assets/images/landscapeportrait2center.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  max-height: 100vh;
}

#color-background {
  background-color: rgba(54, 99, 76, 0.7);
  max-width: 100%;
  width: 100vw;
  height: auto;
}
