#credits {
  width: 100%;
  background-color: #387759;
  font-size: 1vw;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  #credits {
    font-size: 2vw;
  }
}
